import React, { memo } from "react"
import PurpleSolidButton from "../../../components/Buttons/PurpleSolidButton"
const StuckPixelFix = ({ startRepair }) => {
  return (
    <div className="px-3 prose-sm prose sm:prose lg:prose-lg xl:prose-xl md:px-0">
      <p className="lead">
        Haga clic en el botón <code>Reparar</code> y su pantalla se volverá
        negra y Verás un cuadrado con gradientes en él.Arrastra ese cuadrado y
        trae sobre sus píxeles atascados y espere así durante 10 a 15 minutos.
      </p>
      <blockquote>
        No olvide ajustar su configuración de energía para que la pantalla no
        apagar.
      </blockquote>
      <p>
        Puede presionar{" "}
        <span className="px-2 py-1 text-sm font-bold text-red-800 bg-red-100 border-red-300 rounded-sm shadow-sm">
          ESC
        </span>{" "}
        para finalizar el proceso de reparación de píxeles atascados y salir.
      </p>
      <div className="text-center">
        <PurpleSolidButton text="Reparar" onClick={startRepair} />
      </div>
    </div>
  )
}
export default memo(StuckPixelFix)
